// eslint-disable-next-line no-redeclare,no-unused-vars
function fillDashboard(xhr, sortChange) {
  var cont = dashboard.empty();
  if (xhr.boxData)
    $.each(xhr.boxData, function (key, boxData) {
      var settings = fields.start[key];
      if (!key || !settings) return;
      else if (settings.hidden && checkWhere({}, {role: settings.hidden}))
        return;
      var object = settings.object;
      if (!object) object = key;
      var ident = 'dashboard' + ucfirst(key);
      var frame1 = $('<div class="col"></div>').appendTo(cont);
      var frame2 = $(
        '<div class="card dashboard-card" ident="' +
          ident +
          '" key="' +
          key +
          '"></div>'
      ).appendTo(frame1);
      var card = $('<div class="card-body"></div>').appendTo(frame2);
      card.ident = ident;

      // card label
      var cardHeader = $(
        '<div class="d-flex justify-content-between align-items-center mb-2"></div>'
      ).appendTo(card);
      if (!settings.label)
        settings.label =
          parameter[object]['label' + ucfirst(settings.objectSub)];
      var title = $(
        '<h5 class="card-title me-2">' + settings.label + '</h5>'
      ).appendTo(cardHeader);
      if (!settings.icon)
        settings.icon = getParamSettings(
          {object: object, objectSub: settings.objectSub},
          'icon'
        );
      if (settings.icon)
        addIcon(title, settings.icon, null, 'me-2').prependTo(title);

      // buttons
      card.new = function () {
        detail({object: object, objectSub: settings.objectSub});
      };
      card.buttonsLeft = $(
        '<div class="action-buttons action-buttons-left me-auto"></div>'
      ).appendTo(cardHeader);
      if (!settings.noButtons)
        addButtonBar({
          view: card,
          title: settings.label,
          object: object,
          objectSub: settings.objectSub,
          dashboard: true,
          noUpload: true,
        });

      // card options
      // var cardOption = $('<div class="dropdown"></div>').appendTo(cardHeader);
      // var btn = $('<a href="javascript:void(0)" class="dropdown-toggle action-toggle" data-bs-toggle="dropdown" aria-expanded="false"></a>').appendTo(cardOption);
      // $('<i class="icon fa-regular fa-ellipsis-v fa-fw" aria-hidden="true"></i>').appendTo(btn);
      // $('<span class="visually-hidden">Menü</span>').appendTo(btn);
      // buildDropdown(cardOption, ['Karte ausblenden', 'Karte löschen', null, 'Karte wählen', 'Karte bearbeiten']);

      if (settings.input)
        buildField(card, {
          placeholder: info.placeholder.newEntry,
          save: function (value, element) {
            ajax({
              post: true,
              object: object,
              id: 'new',
              send: {title: value},
              cont: card,
            });
            element.val('');
          },
        });

      // card content
      if (!boxData) boxData = [];
      if (settings.preview != null)
        settings.noPreview = !parseInt(settings.preview);
      // chart
      else if (settings.chart) {
        card.addClass('text-center');
        buildChart(card, {
          object: object,
          objectSub: settings.objectSub,
          key: settings.key,
          ids: boxData,
          chartType: settings.chart,
          label: settings.label,
        });
      }

      // kpi
      else if (settings.kpi) {
        var tdValues = {};
        buildFormLine(card, {
          field: 'month',
          save: function (value) {
            ajax({
              object: 'start',
              send: {func: 'findKpi', timespan: value},
              callback: function (xhr) {
                boxData = xhr.boxData;
                $.each(keys, function (i, key) {
                  if (key == 'line') return;
                  var valueSettings = settings[key];
                  var keySettings = getFieldSettings(
                    valueSettings,
                    valueSettings.key
                  );
                  var value = convert({
                    value: boxData[key],
                    settings: keySettings,
                    read: true,
                    entity: true,
                  });
                  tdValues[key].html(value);
                });
              },
            });
          },
        });
        var table = $('<table class="dashboard-table"></table>').appendTo(card);
        var keys = getFieldSettings({object: 'start'}, 'kpi').keys;
        if (!isArray(keys)) keys = keys.split(',');
        $.each(keys, function (i, key) {
          if (key == 'line') {
            $('<tr><td colspan="2"><hr class="m-1"></td></tr>').appendTo(table);
            return;
          }

          var valueSettings = settings[key];
          if (!valueSettings) return;
          var keySettings = getFieldSettings(valueSettings, valueSettings.key);
          var value = convert({
            value: boxData[key],
            settings: keySettings,
            read: true,
            entity: true,
          });

          var tr = $('<tr key="' + key + '"></tr>').appendTo(table);
          var tdLabel = $(
            '<td class="py-1">' + valueSettings.label + ':</td>'
          ).appendTo(tr);
          var icon = valueSettings.icon;
          if (!icon) icon = getParamSettings(valueSettings, 'icon');
          if (icon)
            addIcon(tdLabel, icon, 'list', 'fa-fw fa-lg pe-1').prependTo(
              tdLabel
            );
          var tdValue = $('<td class="py-1">' + value + '</td>').appendTo(tr);
          if (valueSettings.object) {
            tdValue.addClass('link').on(click, function () {
              ajax({
                func: 'overview',
                object: valueSettings.object,
                objectSub: valueSettings.objectSub,
                send: {where: valueSettings.where},
              });
            });
          }
          tdValues[key] = tdValue;
        });
        table.sortable({
          cursor: 'move',
          update: function () {
            var value = table
              .find('tr[key]')
              .map(function () {
                return $(this).attr('key');
              })
              .get();
            saveSettings({
              object: 'start',
              key: 'kpi',
              sub: 'keys',
              value: value,
            });
          },
        });
      }

      // table
      else if (!settings.noPreview) {
        var tableFields = settings.fields;
        if (!tableFields)
          tableFields =
            parameter[object]['tableFields' + ucfirst(settings.objectSub)];
        var pm = {
          ident: ident,
          object: object,
          objectSub: settings.objectSub,
          tableFields: tableFields,
          sort: settings.sort,
          where: settings.where,
          title: settings.label,
          notification: settings.notification,
          noHeader: true,
          noSelect: true,
          idData: true,
          noOverview: true,
        };
        table = new buildTable(card, pm, boxData);
        table.max = 50;
        if (key === sortChange) {
          table.sort = fields.start[key].sort;
          table.loadData({refresh: true});
        }
      }
      if (!settings.kpi && !settings.diagram)
        title
          .addClass('link')
          .addTooltip(settings.label + ' anzeigen')
          .on(click, function () {
            ajax({
              func: 'overview',
              object: object,
              objectSub: settings.objectSub,
              title: settings.label,
              send: {where: settings.where},
            });
          });

      // resize
      frame2.resizable({
        handles: 'e',
        alsoResize: '.dashboard-card',
        stop: function () {
          var width = $(this).width() + 50;
          var colNo = Math.floor(dashboard.width() / width);
          if (colNo > 6) colNo = 6;
          dashboard
            .removeClassStartingWith('row-cols-xxl')
            .addClass('row-cols-xxl-' + colNo);
          $('.dashboard-card').width('auto');
          localStorage.setItem('dashboardColNo', colNo);
        },
      });

      var context = {
        edit: 'Karteneinstellungen',
        tableFields: 'Spaltenmanager',
        hide: info.context.removeCards,
        remove: info.context.deleteCard,
      };
      addCardOptions(card, context, xhr, cont);
    });
}
